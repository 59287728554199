import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import { isIndexPage } from '../../../utils';
import { groupBy } from 'lodash';
import { graphql as gatsbyGraphql, Link } from "gatsby";
export const pageQuery = gatsbyGraphql`
  query verifyArticles {
    pages: allMdx(
      filter: {
        frontmatter: {
          articles: { eq: "verify" }
        }
      }
      sort: { fields: [frontmatter___category], order: ASC }
    ) {
      edges {
        node {
          __typename
          id
          slug
          fileAbsolutePath
          frontmatter {
            title
            subtitle
            category
          }
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Articles",
  "subtitle": "Articles & help regarding Criipto Verify"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <React.Fragment>
  {Object.entries(groupBy(props.data.pages.edges.map(edge => edge.node), 'frontmatter.category')).map(([category, pages]) => <React.Fragment key={category}>
      <h2>{category}</h2>
      <ul>
        {pages.filter(node => !isIndexPage(node)).map(node => <li key={node.id}>
            <Link to={`/${node.slug}/`} mdxType="Link">{node.frontmatter.title}</Link><br />
            {node.frontmatter.subtitle}
          </li>)}
      </ul>
    </React.Fragment>)}
    </React.Fragment>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      